import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import apiconfig from '../Config/Config';

const convertToIST = (utcDateStr) => {
    const date = new Date(utcDateStr);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZone: 'Asia/Kolkata',
    };
    return date.toLocaleDateString('en-IN', options);
};

const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const avdurl1 = apiconfig.develpoment.apibaseUrl;

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await axios.get(avdurl1 + 'admin/subscriptions');
                console.log('response', response);
                setSubscriptions(response.data.subscriptions);
            } catch (err) {
                setError('Failed to fetch subscriptions');
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDetailsClick = (subscription) => {
        setSelectedUser(subscription);
        setIsDialogOpen(true);
    };

    const handleRefundClick = (id) => {
        alert(`Initiate refund for subscription ID: ${id}`);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'user_name', headerName: 'User Name', width: 180 },
        // { field: 'user_email', headerName: 'User Email', width: 200 },
        { field: 'plan_id', headerName: 'Plan ID', width: 150 },
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'start_date', headerName: 'Start Date', width: 180 },
        { field: 'end_date', headerName: 'End Date', width: 180 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button variant="contained" color="primary" onClick={() => handleDetailsClick(params.row)}>
                        Details
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => handleRefundClick(params.row.id)}>
                        Refund
                    </Button>
                </div>
            ),
        },
    ];

    if (loading) {
        return <p>Loading subscriptions...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    console.log('selectedUser', selectedUser);
    return (
        <div className="users-container">
            <center><h1>All Subscriptions</h1></center>
            <DataGrid
                rows={subscriptions}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20]}
                disableSelectionOnClick
            />

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>User Details</DialogTitle>
                <DialogContent>
                    {selectedUser && (
                        <>
                            <Typography><strong>ID:</strong> {selectedUser.id}</Typography>
                            <Typography><strong>User ID:</strong> {selectedUser.user_id}</Typography>
                            <Typography><strong>User Name:</strong> {selectedUser.user_name}</Typography>
                            <Typography><strong>Email:</strong> {selectedUser.user_email}</Typography>
                            <Typography><strong>Plan ID:</strong> {selectedUser.plan_id}</Typography>
                            <Typography><strong>Status:</strong> {selectedUser.status}</Typography>
                            <Typography><strong>Amount:</strong> {selectedUser.amount !== null ? selectedUser.amount : 'N/A'}</Typography>
                            <Typography>
                                <strong>Start Date:</strong> {selectedUser.start_date ? convertToIST(selectedUser.start_date) : 'N/A'}
                            </Typography>
                            <Typography>
                                <strong>End Date:</strong> {selectedUser.end_date ? convertToIST(selectedUser.end_date) : 'N/A'}
                            </Typography>

                            <Typography><strong>Created At:</strong> {new Date(selectedUser.created_at).toLocaleString()}</Typography>
                            <Typography><strong>Updated At:</strong> {new Date(selectedUser.updated_at).toLocaleString()}</Typography>
                            <Typography><strong>Clinic ID:</strong> {selectedUser.clinic_id || 'N/A'}</Typography>
                            <Typography><strong>Image ID:</strong> {selectedUser.image_id || 'N/A'}</Typography>
                            <Typography><strong>Is Active:</strong> {selectedUser.is_active ? 'Yes' : 'No'}</Typography>
                            <Typography><strong>Payment Done:</strong> {selectedUser.payment_done ? 'Yes' : 'No'}</Typography>
                            <Typography><strong>Services Left:</strong> {selectedUser.services_left !== null ? selectedUser.services_left : 'N/A'}</Typography>
                            <Typography><strong>Valid Document:</strong> {selectedUser.valid_doc}</Typography>
                            <Typography><strong>User Type:</strong> {selectedUser.user_type}</Typography>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Subscriptions;
