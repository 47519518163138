import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import apiconfig from "../Config/Config";

export const AllUsers = () => {
    const [usersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const avdurl1 = apiconfig.develpoment.apibaseUrl;

    const handleShow = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedUser(null);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(avdurl1 + "users/allusers", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });

                if (response.data.usersData) {
                    setUsersData(response.data.usersData);
                } else {
                    setError("No data found");
                }
            } catch (err) {
                console.error("Error fetching users:", err);
                setError("Failed to fetch users");
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { field: "user_id", headerName: "User ID", width: 150 },
        { field: "user_name", headerName: "User Name", width: 200 },
        { field: "user_type", headerName: "User Type", width: 150 },
        { field: "mobile_number", headerName: "Mobile Number", width: 180 },
        {
            field: "actions",
            headerName: "Actions",
            width: 200,
            renderCell: (params) => (
                <Button variant="contained" color="info" onClick={() => handleShow(params.row)}>
                    View All Details
                </Button>
            ),
        },
    ];

    if (loading) {
        return <p>Loading users...</p>;
    }

    if (error) {
        return <p style={{ color: "red" }}>{error}</p>;
    }

    return (
        <div className="users-container">
            <center style={{ fontFamily: "Lato" }}>
                <h1>All PetHood Users</h1>
            </center>
            <DataGrid
                rows={usersData}
                columns={columns}
                getRowId={(row) => row.user_id}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
            />
            <Dialog open={showModal} onClose={handleClose}>
                <DialogTitle>User Details</DialogTitle>
                <DialogContent>
                    {selectedUser && (
                        <div>
                            <Typography><strong>User ID:</strong> {selectedUser.user_id}</Typography>
                            <Typography><strong>User Type:</strong> {selectedUser.user_type}</Typography>
                            <Typography><strong>User Name:</strong> {selectedUser.user_name}</Typography>
                            <Typography><strong>Mobile Number:</strong> {selectedUser.mobile_number}</Typography>
                            <Typography><strong>Email:</strong> {selectedUser.email || "N/A"}</Typography>
                            <Typography><strong>Address:</strong> {selectedUser.address || "N/A"}</Typography>
                            <Typography><strong>City:</strong> {selectedUser.city || "N/A"}</Typography>
                            <Typography><strong>State:</strong> {selectedUser.state || "N/A"}</Typography>
                            <Typography><strong>Zip:</strong> {selectedUser.zip || "N/A"}</Typography>
                            <Typography><strong>Country:</strong> {selectedUser.country || "N/A"}</Typography>
                            <Typography><strong>Landmark:</strong> {selectedUser.landmark || "N/A"}</Typography>
                            <Typography><strong>Address Type:</strong> {selectedUser.address_type || "N/A"}</Typography>
                            <Typography><strong>Coordinates:</strong> {selectedUser.lat_cords}, {selectedUser.lan_cords}</Typography>
                            <Typography><strong>Image URL:</strong></Typography>
                            <img
                                src={selectedUser.image_url[0]}
                                alt={`${selectedUser.user_name}'s profile`}
                                style={{ width: "100px", height: "100px", borderRadius: "8px" }}
                            />
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AllUsers;
