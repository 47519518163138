import React, { useState } from "react";
import { FaInstagram, FaWhatsapp, FaFacebook, FaEnvelope } from 'react-icons/fa';
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import './Footer.css';

export const Footer = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");

    const isMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|iPhone|iPad|iPod/i.test(userAgent);
    };

    const whatsappUrl = isMobile()
        ? "whatsapp://send?phone=+918897820507"
        : "https://web.whatsapp.com/send?phone=+918897820507";

    const closeModal = () => {
        setIsModalOpen(false);
        setMobileNumber("");
    };

    const handleDeleteAccount = () => {
        if (mobileNumber === "+918897820507") {
            alert("Account deleted successfully.");
            closeModal();
        } else {
            alert("Invalid mobile number. Account not deleted.");
        }
    };

    return (
        <>
            <div className="social-media-section">
                <div className="our-socials-text">Our socials:</div>
                <div className="social-icons">
                    <a href="https://www.instagram.com/happetindia" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaInstagram size={40} />
                    </a>
                    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaWhatsapp size={40} />
                    </a>
                    <a href="https://www.facebook.com/DeepuMettela" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaFacebook size={40} />
                    </a>
                    <a href="mailto:HR@RO-ONE.IN" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaEnvelope size={40} />
                    </a>
                </div>
            </div>

            <Modal open={isModalOpen} onClose={closeModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center'
                }}>
                    <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                        Delete Account
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Enter mobile number"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteAccount}
                        sx={{ mr: 1 }}
                    >
                        Delete
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                        Cancel
                    </Button>
                </Box>
            </Modal>
        </>
    );
};
