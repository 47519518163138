import React from "react";
import './Aboutus.css';
// import { FaArrowLeft } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";
import { Footer } from "./Footer";

export const Aboutus = () => {
    // const navigate = useNavigate();
    return (
        <>
            {/* <div style={{backgroundColor: '#E66A42'}}>
                <FaArrowLeft
                    size={30}
                    className="backarrow"
                    style={{ color: '#ffffff' }}
                    onClick={() => navigate(-1)}
                />
            </div> */}
            <div className="aboutus-section">
                <h1 className="aboutus-heading">About Us</h1>
                <p className="aboutus-subheading">
                    Your pet's new best friend
                </p>
                <img
                    src="assets/dogsandcat.png"
                    alt="About us"
                    className="aboutus-image"
                />
            </div>

            <div className="founders-section">
                <center className="aboutsection">Our</center>
                <center className="section-title">Founders</center>
                <div className="founders-wrapper">
                    <div className="founder">
                        <img
                            src="assets/champ.webp"
                            alt="About us"
                            className="founder-image"
                        />
                        <p className="founder-description">We are on a mission to make this world a... Wait a minute, let me sniff everything in the world and finish my treats first</p>
                    </div>
                    <div className="founder">
                        <img
                            src="assets/sinchi.webp"
                            alt="About us"
                            className="founder-image"
                        />
                        <p className="founder-description">I don't code, I just decode my way to people's hearts. Being charming and moody are my hobbies..</p>
                    </div>
                </div>
            </div>

            <div className="story-section">
                <center className="section-title">Our Story</center>
                <div className="story-wrapper">
                    <img
                        src="assets/cat+dog+fight.png"
                        alt="Our Story"
                        className="story-image"
                    />
                    <p className="story-description">
                        We at Pethood are committed to redefining pet parenting and making it a more joyful and convenient journey for all pet lovers. With great obsession towards quality and ease we are creating a platform which will be a one stop solution for all your pet parenting needs. We have started this journey by providing a bouquet of doorstep services across Bengaluru, which include grooming, walking, training, vet consulting and nutrition planning. We are a group of passionate pet lovers who ensure a safe and joyful experience is provided to your furry friends and at the same time provide you with flexibility of scheduling appointments that fit in your schedule perfectly.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    )
}
