import React, { useState } from "react";
import axios from "axios";
import { Footer } from "./Footer";
import apiconfig from "../Config/Config";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const avdurl1 = apiconfig.develpoment.apibaseUrl;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPosting(true);
    const body = {
      name: name,
      mail: mail,
      messages: message,
    };
    console.log("body", body);

    try {
      const response = await axios.post(
        avdurl1 + "feedback/feedback",
        body
      );
      console.log("response", response);
      if (response.data.message === "Posted successfully") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Posted successfully",
          text: "We will be in touch with you...",
          showConfirmButton: false,
          timer: 1500,
        });
        setName("");
        setMail("");
        setMessage("");
      }
    } catch (error) {
      console.log("Error submitting feedback:", error);
    } finally {
      setIsPosting(false);
    }
  };

  return (
    <>
      <div className="contactuscontainer" id="contact">
        <div className="row-top-contact">
          <div className="left-top-contact col-xs-12 col-md-6">
            <h1 className="contact-heading">Want To Connect with Us ?</h1>
            <p className="para">
              We're actively working on our launch, and we're constantly
              available for a chat. Do you have any feedback to share? Are there
              specific features you'd like to see? Interested in collaborating
              with us? No matter the reason, don't hesitate to drop us a quick
              message, and we'll be sure to respond promptly.
            </p>
          </div>
          <div className="col-xs-12 col-md-6">
            <form onSubmit={handleSubmit}>
              <input
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Full Name"
                className="fullname-mail"
              />
              <input
                required
                type="email"
                placeholder="Mail"
                className="fullname-mail"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
              <textarea
                cols="30"
                rows="6"
                placeholder="Message"
                className="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <center>
                <button className="send-button" disabled={isPosting}>
                  {isPosting ? "Posting..." : "Send"}
                </button>
              </center>
            </form>
          </div>
        </div>
          <div className="bottom-contact">
            <center>
              <p className="bottom-para">
                Copyright One Love 2022, All Rights Reserved <br /> Andhra
                Pradesh, IN
              </p>
            </center>
          </div>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
