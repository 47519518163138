import React, { useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import OtpInputBoxes from "./OtpInputBoxes";
import { useDispatch } from 'react-redux';
import { getUserData, setToken } from "../Redux/Actions";
import { useNavigate } from "react-router-dom";
import apiconfig from "../Config/Config";
// import { FaArrowLeft } from "react-icons/fa";

export const LoginForm = () => {
    const avdurl1 = apiconfig.develpoment.apibaseUrl;
    console.log('avdurl1', avdurl1);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otpCode, setOtpCode] = useState('');
    const [otpInputs, setOtpInputs] = useState(false);
    const [emailOrMobile, setEmailOrMobile] = useState('');
    const [errors, setErrors] = useState({ emailOrMobile: '', otpCode: '' });
    const [loading, setLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(null);

    const handleOtpChange = (code) => {
        setOtpCode(code);
        if (code.length === 4) {
            setErrors((prevErrors) => ({ ...prevErrors, otpCode: '' }));
        }
    };

    const handleEnterPasswordClick = () => {
        if (!emailOrMobile) {
            setErrors((prevErrors) => ({ ...prevErrors, emailOrMobile: 'Please enter your email or mobile number.' }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, emailOrMobile: '' }));
            setOtpInputs(true);
        }
    };

    const handleVerifyOtpClick = async () => {
        let valid = true;
        if (!emailOrMobile) {
            setErrors((prevErrors) => ({ ...prevErrors, emailOrMobile: 'Please enter your email or mobile number.' }));
            valid = false;
        }
        if (otpCode.length !== 4) {
            setErrors((prevErrors) => ({ ...prevErrors, otpCode: 'Please enter a 4-digit OTP code.' }));
            valid = false;
        }

        if (valid) {
            setLoading(true);
            try {
                // Determine if emailOrMobile is a mobile number or email
                const isMobile = /^[0-9]+$/.test(emailOrMobile);

                const body = isMobile
                    ? { mobile: emailOrMobile, pass: otpCode }
                    : { mail: emailOrMobile, pass: otpCode };
                console.log('body', body);
                const response = await axios.post(avdurl1 + "admin/admin-login", body);
                console.log('response', response);
                if (response.data.adminData) {
                    setLoginSuccess("Login successful!");
                    console.log('Login Successful:', response.data.adminData);
                    localStorage.setItem('adminData', JSON.stringify(response.data.adminData));
                    localStorage.setItem('token', response.data.token);
                    dispatch(getUserData(response.data.adminData));
                    dispatch(setToken(response.data.token));
                    navigate('/');
                } else {
                    setLoginSuccess("Login failed. Please check your OTP and try again.");
                }
            } catch (error) {
                console.error("Login failed:", error);
                setLoginSuccess("An error occurred during login. Please try again later.");
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="loginmaincontainer">
            {/* <FaArrowLeft
                size={30}
                className="backarrow"
                onClick={() => navigate(-1)}
            /> */}
            <div className="login-container">
                <h1 className="welcomeTitle">Welcome to PetHood</h1>
                <div className="CardContainer">
                    <Card style={{ height: '50%', width: '100%' }}>
                        <Card.Body style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Card.Title>Admin Login</Card.Title>

                            {otpInputs ? (
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', height: '50%', width: '100%', position: 'absolute', top: '20%', padding: '15px', justifyContent: 'space-evenly' }}>
                                    <p>Enter 4 Digit Pin</p>
                                    <div className="otp-input-container" style={{ rowGap: '10px' }}>
                                        <OtpInputBoxes
                                            onChange={handleOtpChange}
                                            otpCode={otpCode}
                                            maxLength={4}
                                        />
                                    </div>
                                    {errors.otpCode && <p style={{ color: 'red', fontSize: '0.9rem' }}>{errors.otpCode}</p>}
                                    <Button
                                        onClick={handleVerifyOtpClick}
                                        disabled={loading}
                                        style={{ backgroundColor: "#ff7a54", color: '#ffffff', letterSpacing: '1px', fontWeight: '600', border: 'none', height: '2.5rem', borderRadius: '8px', width: '100%' }}
                                    >
                                        {loading ? "Verifying..." : "Login"}
                                    </Button>
                                    {loginSuccess && <p style={{ color: loginSuccess.includes("successful") ? 'green' : 'red', fontSize: '0.9rem' }}>{loginSuccess}</p>}
                                </div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', height: '50%', width: '100%', position: 'absolute', top: '20%', padding: '15px', justifyContent: 'space-evenly' }}>
                                    <label>Email or Mobile:</label>
                                    <input
                                        type="text"
                                        value={emailOrMobile}
                                        onChange={(e) => {
                                            setEmailOrMobile(e.target.value);
                                            if (e.target.value) {
                                                setErrors((prevErrors) => ({ ...prevErrors, emailOrMobile: '' }));
                                            }
                                        }}
                                        placeholder="Enter email or mobile number"
                                        className="form-control"
                                    />
                                    {errors.emailOrMobile && <p style={{ color: 'red', fontSize: '0.9rem' }}>{errors.emailOrMobile}</p>}
                                    <Button
                                        onClick={handleEnterPasswordClick}
                                        style={{ backgroundColor: "#ff7a54", color: '#ffffff', letterSpacing: '1px', fontWeight: '600', border: 'none', height: '2.5rem', borderRadius: '8px', width: '100%', marginTop: '20%' }}
                                    >
                                        Enter Password
                                    </Button>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};
