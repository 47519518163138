import React from "react";

const TermsAndConditions = () => {
  return (
    <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '5%' }}>
      <center>
        <h1 style={{color: '#000000', fontSize: '16pt', fontFamily: "Times New Roman"}}>TERMS OF USE</h1>
        <hr style={{ border: '1px solid #000', width: '100%', margin: 'auto' }}></hr>
      </center>
      <div style={{ textAlign: 'right' }}>
        <p className="headtext">Posted as of 01 February, 2022</p>
        <p className="headtext">Last updated as of 29 June, 2022</p>
      </div>
      <center className="headtext"><h5>Welcome to Pethood’s Terms of Use</h5></center>
      <div>
        <p className="paratext">
          This Website is created and operated by M/s. Ro-one Information Technology & Digital Solutions Private Limited , (hereinafter referred to as “We”, “Our”, and “Us”) having its registered address at “Sy 13/5, Hulimavu Main Road, Bannerghatta Main Rd, near Sri Sairam Hospital, Bengaluru, Karnataka 560076, India” and operating under the brand name “Pethood” (“Brand Name”). We intend to ensure your steady commitment to the usage of this Website and the services provided by us through our Website “https://onelovepc.com”.
        </p>
        <p className="paratext">
          For the purpose of these Terms of Use (“Terms of Use”), wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the Website. “You”, “Your”, “Yourself”, “User” shall mean and refer to natural and legal individuals who shall be users of this Website provided by us and who is competent to enter into binding contracts, as per law. “Third Parties” refer to any Website, platform or individual apart from the Users and the creator of this Website.
        </p>
        <center><b><p className="headtext">GENERAL TERMS</p></b></center>
        <p className="subparatext">i.  The headings of each section in these Terms of Use are only for the purpose of organizing the various provisions under these Terms of Use in an orderly manner and shall be used by you to interpret the provisions contained herein in a manner as may apply to you. Further, it is specifically agreed by you that the headings shall have legal or contractual value on your usage of the Website.</p>
        <p className="subparatext">
          ii. The use of this Website is solely governed by these Terms of Use, Privacy Policy that may be uploaded on the Website and any modifications or amendments made thereto by us from time to time, at our sole discretion. If you as a user continue to access and use this Website, you are agreeing to comply with and be bound by the following Terms of Use and Privacy Policy.
        </p>
        <p className="subparatext">
          iii. You expressly agree and acknowledge that these Terms of Use and Privacy Policy are co-terminus in nature and that expiry/termination of either one will lead to the termination of the other.
        </p>
        <p className="subparatext">iv. You unequivocally agree that these Terms of Use and the Privacy Policy constitute a legally binding agreement between us, and that you shall be subject to the rules, guidelines, policies, terms, and conditions applicable to your use of the Website, and that the same shall be deemed to be incorporated into these Terms of Use and shall be treated as part and parcel of the same. You acknowledge and agree that no signature or express act is required to make these Terms of Use and the Privacy Policy binding on you and that your act of visiting any part of the Website constitutes your full and final acceptance of these Terms of Use and the Privacy Policy.</p>
        <p className="subparatext">
          v. We reserve the sole and exclusive right to amend or modify these Terms of Use without any prior permission or intimation to you, and you expressly agree that any such amendments or modifications shall come into effect immediately. If you do not adhere to the changes, You must stop using the Website at once. Your continued use of the Website will signify your acceptance of the changed terms.
        </p>
        <center className="headtext"><b>PLATFORM OVERVIEW</b></center>
        <p className="paratext">
          The Platform is an online e-commerce website founded by a group of pet lovers as a pet care startup that provides best in class pet care services. The Platform is committed to providing value services for all pet’s daily routine including a Walker, Groomer, Vet, Nutritionist, Trainer/behaviorist, Boarding, etc.
        </p>
        <p className="paratext">
          The role of the Platform is to create an online marketplace that consists of various brands/ vendors/Partners listing their pet care services of general utility in nature. The Platform undertakes a screening/ review of each service and the qualifications before listing it out to confirm the Partner’s service features. The Platform provides information content in the form of blogs, topics ranging from pet-living, service information, pet diet-plans, facts and news, animal welfare, and environmental information.
        </p>
        <p className="paratext">
          The Platform shall be operational and providing services worldwide including different countries and shall abide to the laws and regulations of respective countries.
        </p>
        <center className="headtext"><b>REGISTRATION</b></center>
        <p className="paratext">
          Registration on the Platform is mandatory for Users of the Website. The Users can register by providing the following information:
        </p>
        <p className="subparatext">i. Name</p>
        <p className="subparatext">ii. Phone Number</p>
        <p className="subparatext">iii. Email ID</p>
        <p className="subparatext">iv. Address</p>
        <p className="subparatext">v. City</p>
        <center className="headtext"><b>ELIGIBILITY</b></center>
        <p className="subparatext">a.  You represent and warrant that you are competent and eligible to enter into legally binding agreements and of age and that you have the requisite authority to bind themselves to these Terms of Use following the Law. However, if you are a minor using this Website, you may do so with the consent of your legal guardian. All Acts of minor shall be deemed to be considered as the acts undertaken under the supervision of their legal guardian.</p>
        <p className="subparatext">b.  You further represent that you will comply with these Terms of Use and all applicable local, state, national and international laws, rules and regulations.</p>
        <p className="subparatext">c.  You shall not use the Website if you are not competent to contract or are disqualified from doing so by any other applicable law, rule or regulation currently in force.</p>
        <center className="headtext"><b>PAYMENT GATEWAY</b></center>
        <p className="paratext">
          For making all payments for services on the Website, you shall be required to make payment for which you will be redirected to a third-party payment gateway that we may have an agreement with. The payment gateway may additionally seek information from you to process the payment for your purchase and additionally may charge you the payment gateway charges that may be applicable. The payment gateway consists of Debit Card/Credit Card/Net Banking/UPI and other wallet options. You shall be governed under the concerned payment gateway’s Terms and Conditions and other Policies for the purpose of all payment-related aspects.
        </p>
        <center className="headtext"><b>CONTENT</b></center>
        <p className="paratext">
          All text, graphics, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music and artwork (collectively, ‘Content’), is generated/provided or based on information provided by the users or third parties and We have no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the Website.
        </p>
        <p className="paratext">
          All the Content displayed on the Website is subject to copyright and shall not be reused by You (or a third party) without the prior written consent from Us and the copyright owner.
        </p>
        <p className="paratext">
          You are solely responsible for the integrity, authenticity, quality and genuineness of the content provided on the Website and whilst feedback and comments by You can be made via the Website, We bear no liability whatsoever for any feedback or comments made by the other Users or made in respect of any of the content on the Website. Further, the Website reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the Website or to terminate the account of any User who is found to have created or shared or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading.
        </p>
        <p className="paratext">
          You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the Website. You shall not copy, adapt, and modify any content without written permission from Us.
        </p>
        <center className="headtext"><b>INDEMNITY</b></center>
        <p className="paratext">
          You agree to indemnify, defend and hold harmless the Us, and our respective directors, officers, employees and agents (collectively, "Parties"), from and against any losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed according to these Terms of Use. Further, You agrees to hold the Us harmless against any claims made by any third party due to, or arising out of, or in connection with:
        </p>
        <p className="subparatext">a. Your use of the Website,</p>
        <p className="subparatext">b. Your violation of these Terms of Use;</p>
        <p className="subparatext">c. Your violation of any rights of another;</p>
        <p className="subparatext">d. Your alleged improper conduct according to these Terms of use;</p>
        <p className="subparatext">e. Your conduct in connection with the Website;</p>
        <p className="paratext">
          You agree to fully cooperate in indemnifying Us at your expense. You also agree not to settle with any party without consent from Us.
        </p>
        <p className="paratext">
          In no event shall we be liable to compensate You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not You had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the Your use of or access to the Website and/or the Services or materials contained therein.
        </p>
        <center className="headtext"><b>LIMITATION OF LIABILITY</b></center>
        <p className="subparatext">a.  We are not responsible for any consequences arising out of the following events:</p>
        <p className="subparatext">i. If the Website is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure;</p>
        <p className="subparatext">ii. If you have fed incorrect information or data or for any deletion of data;</p>
        <p className="subparatext">iii. If there is an undue delay or inability to communicate through email;</p>
        <p className="subparatext">iv. If there is any deficiency or defect in the Services managed by Us;</p>
        <p className="subparatext">v. If there is a failure in the functioning of any other service provided by Us.</p>
        <p className="subparatext">b. The Website accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to You, Your belongings, or any third party, resulting from the use or misuse of the Website or any service availed of by Us through the Website. The service and any Content or material displayed on the service is provided without any guarantees, conditions or warranties as to its accuracy, suitability, completeness or reliability. We will not be liable to you for the unavailability or failure of the Website.</p>
        <p className="subparatext">c.  You are to comply with all laws applicable to you or your activities, and with all Policies, which are hereby incorporated into this Terms of Use by reference.</p>
        <p className="subparatext">d.  The Website expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the Website and which is incurred by you in connection with the Website, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of Use.</p>
        <p className="subparatext">e.  To the fullest extent permitted by law, We shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Website.</p>
        <p className="subparatext">d.  To the fullest extent permitted by law, The various brands/ vendors/Partners working with the Us shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Website.</p>
        <center className="headtext"><b>TERM</b></center>
        <p className="subparatext">a. These Terms of Use shall continue to form a valid and binding contract between us and shall continue to be in full force and effect until you continue to access and use the Websites.</p>
        <p className="subparatext">b.  You may terminate your use of the Website at any time.</p>
        <p className="subparatext">c.  We may terminate these Terms of Use and close your account at any time without notice and/or suspend or terminate Your access to the Website at any time and for any reason, if any discrepancy or legal issue arises.</p>
        <p className="subparatext">d.  Such suspension or termination shall not limit our right to take any other action against you that we consider appropriate.</p>
        <p className="subparatext">e.  It is also hereby declared that we may discontinue the Services and Websites without any prior notice.</p>
        <center className="headtext"><b>TERMINATION</b></center>
        <p className="subparatext">a.  We reserve the right, in its sole discretion, to unilaterally terminate Your access to the Website, or any portion thereof, at any time, without notice or cause.</p>
        <p className="subparatext">b.  We also reserve the universal right to deny access to You, to any/all of are on its Website without any prior notice/explanation to protect the interests of the Website and/or other Users to the Website.</p>
        <p className="subparatext">c.  We reserve the right to limit, deny or create different access to the Website and its features concerning different Users, or to change any of the features or introduce new features without prior notice.</p>
        <p className="subparatext">d.  You shall continue to be bound by these Terms of use, and it is expressly agreed to by You that You shall not have the right to terminate these Terms of Use till the expiry of the same.</p>
        <center className="headtext"><b>COMMUNICATION</b></center>
        <p className="paratext">
          By using this Website and providing Your identity and contact information to Us through the Website, You agree and consent to receive e-mails or SMS from Us and/or any of its representatives at any time.
        </p>
        <p className="paratext">
          You can report to “social@Pethood.pet” if you find any discrepancy with regard to Website or content-related information and we will take necessary action after an investigation.  The response with resolution (if any issues found) shall be dependent on the time is taken for investigation.
        </p>
        <p className="paratext">
          You expressly agree that notwithstanding anything contained hereinabove, it may be contacted by Us (via following, but not limited to, mediums: SMS, Whatsapp, Phone call, Email, In person meeting, Post) relating to any services availed by You on the Website or anything pursuant thereto and You agree to indemnify Us from any harassment claims. It is expressly agreed to by Us that any information shared by Us shall be governed by the Privacy Policy.
        </p>
        <center className="headtext"><b>USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT</b></center>
        <p className="paratext">You agree and acknowledges that you are a restricted user of this Website and you:</p>
        <ol type="a">
          <li className="subparatext">Agree to provide genuine credentials during the process whenever required on the Website. You shall not use a fictitious identity. We are not liable if you have provided incorrect information.</li>
          <li className="subparatext">Agree to ensure the Name, Email address, Phone Number, and any such other information that may be provided and is valid at all times and shall keep your information accurate and up-to-date.</li>
          <li className="subparatext">You agree that You are solely responsible for maintaining the confidentiality of your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason.</li>
          <li className="subparatext">Understand and acknowledge that the data submitted is manually entered into the database of the Website. You also acknowledge the fact that data so entered into the database is for easy and ready reference for You, and to streamline the Services through the Website.</li>
          <li className="subparatext">Authorize the Website to use, store or otherwise process certain personal information and all published Content, responses, locations, User comments, reviews and ratings for personalization of Services, marketing and promotional purposes and for optimisation of User-related options and Services.</li>
          <li className="subparatext">Understand and agree that, to the fullest extent permissible by law, the Website or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the Website or this terms of use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages.</li>
          <li className="subparatext">Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the Website. Any such use/limited use of the Website will only be allowed with the prior express written permission.</li>
          <li className="subparatext">Agree not to access (or attempt to access) the Website and/or the materials or Services by any means other than through the interface provided by the Website. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or its content, or in any way reproduce or circumvent the navigational structure or presentation of the Website, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Website will lead to suspension or termination of your access to the Website. We disclaim any liabilities arising concerning such offensive content on the Website.</li>
          <li className="subparatext">Expressly agree and acknowledge that the Content generated by the Users and displayed on the Website is not owned by Us and that We are in no way responsible for the content of the same. You may, however, report any offensive or objectionable content, which We may then remove from the Website, at our sole discretion.</li>
        </ol>
        <p className="paratext">You further undertake not to:</p>
        <ol type="a">
          <li className="subparatext">Engage in any activity that interferes with or disrupts access to the Website or the services provided therein (or the servers and networks which are connected to the Website);</li>
          <li className="subparatext">Impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity;</li>
          <li className="subparatext">Probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website. The User may not reverse look-up, trace or seek to trace any information relating to any other User of, or visitor to, the Website, or any other viewer of the Website, including any User account maintained on the Website not operated/managed by the User, or exploit the Website or information made available or offered by or through the Website, in any manner;</li>
          <li className="subparatext">Disrupt or interfere with the security of, or otherwise cause harm to, the Website, systems resources, accounts, passwords, servers, or networks connected to or accessible through the Website or any affiliated or linked Websites.</li>
          <li className="subparatext">Use the Website or any material or content therein for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of this Website or any other third party (s);</li>
          <li className="subparatext">Violate any code of conduct or guideline which may apply for or to any particular service offered on the Website;</li>
          <li className="subparatext">Violate any applicable laws, rules or regulations currently in force within or outside India;</li>
          <li className="subparatext">Violate any portion of these Terms of Use or the Privacy Policy, including but not limited to any applicable additional terms of the Website contained herein or elsewhere, whether made by amendment, modification, or otherwise;</li>
          <li className="subparatext">Commit any act that causes Us to lose (in whole or in part) the Services of its Internet Establishment ("ISP") or in any manner disrupts the Services of any other supplier/service provider of the Website;</li>
          <li className="subparatext">You hereby expressly authorize Us to disclose any and all information relating to You in our possession to law enforcement or other government officials, as We may in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. You further understand that the Website might be directed to disclose any information (including the identity of persons providing information or materials on the Website) as necessary to satisfy any judicial Order, law, regulation or valid governmental request.</li>
          <li className="subparatext">By indicating Your acceptance to use any services offered on the Website, You are obligated to complete such transactions after making payment. You shall be prohibited from indicating their acceptance to avail services where the transactions have remained incomplete.</li>
          <li className="subparatext">You agree to use the services provided by Us, our affiliates, consultants and contracted companies, for lawful purposes only.</li>
          <li className="subparatext">You agree to provide authentic and true information. We reserve the right to confirm and validate the information and other details provided by You at any point in time. If upon confirmation such details are found to be false, not to be true (wholly or partly), We shall in our sole discretion reject the order and debar You from using the Website without prior intimation whatsoever.</li>
          <li className="subparatext">You agree not to post any material on the Website that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful or advertising any goods or services. More specifically, You agree not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that:</li>
        </ol>
        <ol type="i">
          <li className="subparatext">belongs to another person and to which you have no right to;</li>
          <li className="subparatext"> is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
          <li className="subparatext"> is in any way harmful to minors;</li>
          <li className="subparatext"> infringes any patent, trademark, copyright or other proprietary rights;</li>
          <li className="subparatext"> violates any law for the time being in force;</li>
          <li className="subparatext"> deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing;</li>
          <li className="subparatext"> Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</li>
          <li className="subparatext"> Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</li>
          <li className="subparatext"> Publish, post, disseminate, any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</li>
          <li className="subparatext"> Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
        </ol>
        <center className="headtext"><b>SUSPENSION OF USER ACCESS AND ACTIVITY</b></center>
        <p className="paratext">
          Notwithstanding other legal remedies that may be available, we may in our sole discretion, limit your access and/or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend/terminate your association with the Website, and/or refuse to the usage of the Website, without being required to provide you with notice or cause:
        </p>
        <ol type="a">
          <li className="subparatext">If you are in breach of any of these Terms of Use or the Privacy Policy;</li>
          <li className="subparatext"> If you have provided wrong, inaccurate, incomplete or incorrect information;</li>
          <li className="subparatext"> If your actions may cause any harm, damage or loss to the other Users or Us, at our sole discretion.</li>
        </ol>
        <center className="headtext"><b>
          INTELLECTUAL PROPERTY RIGHTS</b></center>
        <p className="paratext">
          Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms of Use that shall be available on the Website. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the Website or such other third party and other distinctive brand features of the Website are the property of the Website or the respective copyright or trademark owner. Furthermore, concerning the Website, we shall be the exclusive owner of all the designs, graphics and the like, related to the Website.
        </p>
        <p className="paratext">
          You shall not use any of the intellectual property displayed on the Website in any manner that is likely to cause confusion among existing or prospective users of the Website, or that in any manner disparages or discredits the Website, to be determined in the sole discretion.
        </p>
        <p className="paratext">
          You are aware all intellectual property, including but not limited to copyrights, relating to said services resides with the owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators. You are aware that we merely provide the Website through which You can communicate with other users and the Website does not own any of the intellectual property relating to the independent content displayed on the Website, apart from created graphics and specified content.
        </p>
        <p className="paratext">
          You are further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by You will result in legal action being initiated against You by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed to by You that the contents of this section shall survive even after the termination or expiry of these Terms of Use and/or Privacy Policy.
        </p>
        <center className="headtext"><b>DISCLAIMER OF WARRANTIES AND LIABILITIES</b></center>
        <ol type="a">
          <li className="subparatext">You further agree and undertake that you are accessing the Website at your sole risk and are that you are using the best and prudent judgment before availing of any features on the Website or accessing/using any information displayed thereon.</li>
          <li className="subparatext">You agree that any kind of information, resources, activities, recommendations obtained/availed from the Website, written or oral, will not create any warranty and we disclaim all liabilities resulting from these.</li>
          <li className="subparatext">We do not guarantee that the features and content contained in the Website will be uninterrupted or error-free, or that the Website or its server will be free of viruses or other harmful components, and You hereby expressly accepts any associated risks involved with your use of the Website.</li>
          <li className="subparatext">It is further agreed to by You that the contents of this section shall survive even after the termination or expiry of the Terms of Use and/or Privacy Policy.</li>
        </ol>
        <center className="headtext"><b>FORCE MAJEURE</b></center>
        <p className="paratext">
          We will not be liable for damages for any delay or failure to perform our obligations hereunder if such delay or failure is due to cause beyond our control or without its fault or negligence, due to Force Majeure events including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.
        </p>
        <center className="headtext"><b>DISPUTE RESOLUTION AND JURISDICTION</b></center>
        <p className="paratext">
          In the event of any dispute arising out of or in connection with this agreement, including any dispute relating to the validity of this agreement, the parties shall, at first instance, attempt to resolve the dispute by mediation administered by the Centre for Online Resolution of Disputes (“CORD”) (www.resolveoncord.com) and conducted in accordance with the CORD Rules of Mediation, by a sole mediator to be appointed by CORD.
        </p>
        <p className="paratext">
          In the event the parties are unable to resolve any such dispute through mediation within 45 days of the initiation of the dispute, subject to a contrary agreement between the parties, the dispute shall be finally resolved by arbitration administered by CORD and conducted in accordance with the Rules of Arbitration of CORD, by a sole arbitrator to be appointed by CORD. The language of arbitration shall be English. The seat of arbitration shall be Bengaluru, Karnataka, India.
        </p>
        <p className="paratext">
          You expressly agree that the Terms of Use, Privacy Policy and any other agreements entered into between the Parties are governed by the laws, rules, and regulations of India.
        </p>
        <center className="headtext"><b>MISCELLANEOUS PROVISIONS</b></center>
        <ol type="a">
          <li className="subparatext"> Entire Agreement: These Terms of Use, read with the Privacy Policy, form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto.</li>
          <li className="subparatext"> Waiver: The failure at any time to require performance of any provision of these Terms of Use shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of Use, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of Use.</li>
          <li className="subparatext"> Severability: If any provision/clause of these Terms of Use is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of Use shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of Use shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms of Use shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.</li>
          <li className="subparatext"> Contact Us: If you have any questions about these Terms of Use, the practices of the Website, or your experience, you can contact us by emailing us at social@Pethood.pet or by writing to us at </li>
        </ol>
        <center>
          <p className="paratext">Gundlapalli, Ongole, Prakasam Dist, Andhra Pradesh, India, 523001.</p>
        </center>
      </div>
    </div>
  );
};

export default TermsAndConditions;
