import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OtpInputBoxes from "./OtpInputBoxes";
import { userProfileData } from "../Services/Service";
import CountrycodeInput from "./CountrycodeInput";
import { auth } from "./firebase";

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpInputs, setOtpInputs] = useState(false);
    const [phoneNumberError, setPhoneNumbererror] = useState('');
    const [user, setUser] = useState(null);

    async function isUserRegistered(mobile) {
        if (mobile.length === 0) {
            setPhoneNumbererror('Please enter a phone number');
        } else if (mobile.length < 10) {
            setPhoneNumbererror('Phone number should be 10 digits');
        } else {
            setPhoneNumbererror('');
            try {
                const result = await userProfileData(mobile).catch(err => { console.log('isUserRegistered', err) });
                console.log('result', result);
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('userData', JSON.stringify(result?.registrationData));
                localStorage.setItem('accessToken', result?.token);
                if (result.message === 'No data found for the provided id.') {
                    alert("Please register first");
                } else if (result.registrationData !== null) {
                    if (
                        mobile === '1111111111' ||
                        mobile === '2222222222' ||
                        mobile === '3333333333' ||
                        mobile === '4444444444'
                    ) {
                        window.location.href = '/'
                    } else {
                        sendOTP();
                    }
                }
            } catch (err) {
                console.log('Function Error', err);
            }
        }
    }

    const sendOTP = async () => {
        try {
            const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
                size: 'invisible',
            });
            console.log("recaptcha sent", recaptcha);
            const formattedPhoneNumber = '+91' + phoneNumber;
            const confirmation = await signInWithPhoneNumber(
                auth,
                formattedPhoneNumber,
                recaptcha
            ).catch(err => { console.log('sendOTP', err) });
            setUser(confirmation);
            console.log(confirmation);
            setOtpInputs(true)
        } catch (err) {
            console.log("Error in Sending OTP is", err);
        }
    };

    const verifyOtp = async () => {
        if (otp !== "") {
            try {
                const data = await user.confirm(otp).catch(err => console.log('verifyotp', err));
                console.log(data);
                console.log(user);
                alert("OTP verified");
                setOtpInputs(false)
                setPhoneNumber('');
                setOtp('');
                window.location.href = '/home'
            } catch (err) {
                console.log('Error in OTP verification', err);
            }
        } else {
            alert("Enter otp");
        }
    };

    const handlePhoneNumberChange = text => {
        setPhoneNumber(text);
        setPhoneNumbererror('');
    };
    const [otpCode, setOtpCode] = useState('');
    // const [error, setError] = useState(false);

    const handleOtpChange = (code) => {
        setOtpCode(code);
        if (code.length === 6) {
            // Example logic to verify OTP
            console.log('OTP entered:', code);
        }
    };

    // const handleBlur = () => {
    //   // Logic for onBlur (when the input loses focus)
    //   if (otpCode.length < 6) {
    //     setError(true);
    //   } else {
    //     setError(false);
    //   }
    // };
    return (
      <>
        <div className="login-container">
            <h1 className="welcomeTitle">Welcome to PetHood</h1>
            <div className="CardContainer">
                <Card style={{ height: '80%', width: '100%' }}>
                    <Card.Body style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Card.Title>Login</Card.Title>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', height: '80%', width: '100%', position: 'absolute', top: '20%', padding: '0px 15px', justifyContent: 'space-between' }}>
                            <CountrycodeInput onChange={handlePhoneNumberChange} />
                            {phoneNumberError && <p style={{ color: 'red' }}>{phoneNumberError}</p>}
                            {otpInputs ? null :
                                (<Button variant="transparent" onClick={() => isUserRegistered(phoneNumber)} style={{ backgroundColor: "#ff7a54", color: '#ffffff', letterSpacing: '1px', fontWeight: '600' }}>Get OTP</Button>)
                            }
                            {otpInputs ? null : (
                                <>
                                    <div id="recaptcha"></div>
                                    <div id="recaptcharesend"></div>
                                </>
                            )}
                        </div>
                        {otpInputs && (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', height: '-10px', width: '100%', padding: '0px 15px', justifyContent: 'space-between', zIndex: '999' }}>
                                <p>Enter OTP</p>
                                <div className="otp-input-container" style={{ rowGap: '10px' }}>
                                    {/* <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        renderInput={(props) => <input {...props} />}
                                        className="otp-input-container input"
                                        inputStyle={{ width: '2.5rem', height: '2.5rem' }} /> */}
                                    <OtpInputBoxes
                                        onChange={handleOtpChange}
                                        otpCode={otpCode}
                                        maxLength={6}
                                    />
                                </div>
                                <Button
                                    onClick={verifyOtp}
                                    style={{ backgroundColor: "#ff7a54", color: '#ffffff', letterSpacing: '1px', fontWeight: '600', border: 'none', height: '2.5rem', borderRadius: '8px', width: '100%' }}
                                >
                                    Verify OTP
                                </Button>
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </div>
        </>
    );
};

export default Login;
