import React, { useState } from "react";

const CountrycodeInput = ({ onChange }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [selectedCountryCode, setSelectedCountryCode] = useState({
  //   name: 'India',
  //   dial_code: '+91',
  //   emoji: '🇮🇳',
  //   code: 'IN',
  // });

  const handlePhoneNumberChange = (e) => {
    const text = e.target.value;
    const numericText = text.replace(/\D/g, '');
    const formattedText = numericText.slice(0, 10);
    setPhoneNumber(formattedText);
    onChange(formattedText);
  };

  return (
    <div>
      <p style={{
    marginTop: '0px',
    marginBottom: '1rem',
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'capitalize',
    color: 'rgb(102, 102, 102, 0.7)',
    letterSpacing: '0.4px',
    }}>Enter Mobile Number</p>
      <div className="input-wrapper">
        <p>+91</p>
        <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} style={{border: 'none',outline: 'none',boxShadow: 'none',backgroundColor: 'transparent',}} placeholder="Mobile Number"/>
      </div>
    </div>
  );
}

export default CountrycodeInput;
