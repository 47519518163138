import React, { useEffect, useRef } from 'react';
import './OtpInput.css';

const OtpInputBoxes = ({
  onChange,
  onBlur,
  otpCode,
  maxLength = 6,
  error,
  size = 'large',
  disabled = false,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!disabled) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 200);
    }
  }, [disabled]);

  useEffect(() => {
    if (otpCode === '' && !disabled) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 200);
    }
  }, [otpCode, disabled]);

  return (
    <>
      <div className="container-input">
        <input
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          value={otpCode}
          maxLength={maxLength}
          type="tel"
          className="otp-hidden-input"
          ref={inputRef}
          disabled={disabled}
        />
        <div className="container-input">
          {Array(maxLength)
            .fill(0)
            .map((_, index) => (
              <button
                onClick={() => {
                  if (!disabled) {
                    inputRef?.current?.focus();
                  }
                }}
                key={index}
                disabled={disabled}
                className="cell-button"
              >
                <div
                  className={`cell-view ${
                    size === 'small' ? 'small-cell-view' : ''
                  } ${otpCode?.[index] ? 'active-cell-view' : ''} ${
                    error ? 'error-cell-view' : ''
                  }`}
                >
                  <span
                    className={`otp-text ${
                      otpCode?.[index] ? 'otp-active-text' : ''
                    } ${error ? 'error-active-text' : ''}`}
                  >
                    {otpCode?.[index] || '_'}
                  </span>
                </div>
              </button>
            ))}
        </div>
      </div>
    </>
  );
};

export default OtpInputBoxes;
