import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, CircularProgress } from "@mui/material";
import apiconfig from "../Config/Config";

export const AllFeedBacks = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const avdurl1 = apiconfig.develpoment.apibaseUrl;

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await axios.get(avdurl1 + 'feedback/feedbacks', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Adjust token storage as needed
          },
        });
        setFeedbacks(response.data.Data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load feedbacks.");
        setLoading(false);
      }
    };

    fetchFeedbacks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const columns = [
    { field: "feedback_id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "mail", headerName: "Email", width: 200 },
    { field: "messages", headerName: "Message", width: 300 },
    { field: "formatted_date_time", headerName: "Date & Time", width: 200 },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        All Feedbacks
      </Typography>
      <DataGrid
        rows={feedbacks.map((feedback, index) => ({
          ...feedback,
          id: feedback.feedback_id || index, // Assign `id` for DataGrid key
        }))}
        columns={columns}
        initialState={{
            pagination: {
                paginationModel: {
                    pageSize: 10,
                },
            },
        }}
        pageSizeOptions={[5, 10, 20]}
        disableSelectionOnClick
      />
    </Box>
  );
};
