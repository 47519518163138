import React from "react";

export const RefundPolicy = () => {
    return (
        <>
            <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '5%' }}>
                <center>
                    <h1 style={{color: '#000000', fontSize: '16pt', fontFamily: "Times New Roman"}}>REFUND POLICY</h1>
                    <hr style={{ border: '1px solid #000', width: '100%', margin: 'auto' }}></hr>
                </center>
                <div style={{ textAlign: 'left' }}>
                    <p className="paratext">Last updated date: May 11, 2023</p>
                </div>
                <div>
                    <p className="headtext"><b>1. GENERAL</b></p>
                    <p className="parasubtext">a. This website with the URL of https://onelovepc.com ("Website/Site") is operated by Ro-one Information Technology & Digital Solutions Private Limited  ("We/Our/Us").</p>
                    <p className="parasubtext">b. We are committed to providing Our customers with the highest quality Services. However, on rare occasions, Services may be found to be deficient. In such cases, We offer refund in accordance with this Refund Policy ("Policy").</p>
                    <p className="parasubtext">c. You are advised to read Our Terms and Conditions along with this Policy.</p>
                    <p className="parasubtext">d. By using this website, You agree to be bound by the terms contained in this Policy without modification. If You do not agree to the terms contained in this Policy, You are advised not to transact on this website.</p>
                    <p className="parasubtext">e. We offer a 2 days refund Policy for the eligible Services.</p>
                    <p className="parasubtext">f. Please read this Policy before availing service on this Website, so that You understand Your rights as well as what You can expect from Us if You are not happy with Your purchase.</p>
                    <p className="headtext"><b>2. DEFINITIONS</b></p>
                    <p className="parasubtext">a. "Business Days" - means a day that is not a Saturday, Sunday, public holiday, or bank holiday in India or in the state where our office is located.</p>
                    <p className="parasubtext">b. "Customer" - means a person who avails services for consideration and does not include commercial purchases.</p>
                    <p className="parasubtext">c. "Date of Transaction" - means the date of invoice of the service, which includes the date of renewal processed in accordance with the terms and conditions of the applicable service agreement.</p>
                    <p className="parasubtext">d. "Website" - means this website with the URL: https://onelovepc.com.</p>
                    <p className="headtext"><b>3. REFUNDS RULES</b></p>
                    <p className="paratext">
                        a. Every effort is made so as to service the orders placed, as per the specifications and timelines mentioned with respect to a Services. If due to any unforeseen circumstances or limitations from Our side, the service is not provided then such order stands cancelled and the amount paid by You is refunded.
                    </p>
                    <p className="parasubtext">b. We will not process a refund if You have placed the order for the wrong service.</p>
                    <p className="parasubtext">c. When you make a qualifying refund request. We may refund the full amount, less any additional cost incurred by Us in providing such Services.</p>
                    <p className="parasubtext">d. Refund shall only be considered once the Customer concerned produces relevant documents and proof.</p>
                    <p className="parasubtext">e. Once qualified, the refunds are applied to the original payment option.</p>
                    <p className="parasubtext">f. The request for a refund of Services can be made in the following manner:</p>
                    <p className="parasubtext">Reach out to Pethood via email - support@Pethood.pet</p>
                    <p className="parasubtext">g. In the event that a request for discontinuing a service is made, the cancellation charges shall become applicable as per the company policies.</p>
                    <p className="headtext"><b>4. ORDER NOT CONFIRMED BUT AMOUNT DEDUCTED</b></p>
                    <p className="parasubtext">a. If the amount has been deducted and the order is not confirmed, please do contact Your respective bank. It takes 7 (seven) Business Days to reverse back the amount by the respective bank.</p>
                    <p className="parasubtext">b. If the issue has not been resolved within 7 (seven) Business Days, you can contact Our customer care support as follows: support@Pethood.pet.</p>
                    <p className="headtext"><b>5. EXEMPTIONS</b></p>
                    <p className="parasubtext">a. Notwithstanding the other provisions of this Policy, We may refuse to provide a refund for a service if:</p>
                    <p className="parasubtext2">I. You knew or were made aware of the problem(s) with the service before you availed it.</p>
                    <p className="parasubtext2">II. Free Services.</p>
                    <p className="parasubtext2">III. Refund requests are placed after the refund window is closed.</p>
                    <p className="parasubtext">b. Apart from the above, the following exemptions are applicable when it comes to the refund:</p>
                    <p className="parasubtext2">i) Services delivered/completed </p>
                    <p className="parasubtext2">ii) Within 48 hours of the appointment time</p>
                    <p className="headtext"><b>6. YOUR DATA</b></p>
                    <p className="paratext">The privacy of your data supplied to Us during the return and refund procedure is also governed by our privacy policy.</p>
                    <p className="headtext"><b>7. RESPONSE TIME</b></p>
                    <p className="parasubtext">a. Refunds are normally processed within 10 Business Days after checking the veracity of the refund request.</p>
                    <p className="parasubtext">b. The period of refund may also depend on various banking and payment channels, and We will not be liable for any errors or delays in a refund due to banks or third-party service providers.</p>
                    <p className="headtext"><b>8. CANCELLATION OF RETURN REQUEST</b></p>
                    <p className="paratext">A request for return or refund once made can be cancelled by contacting customer care at support@Pethood.pet.</p>
                    <p className="headtext"><b>9. REFUSAL OF RETURN OR REFUND REQUEST</b></p>
                    <p className="paratext">We reserve the right to refuse any request for a refund if such request is not in compliance with this Policy or applicable laws.</p>
                    <p className="headtext"><b>10. CHANGES TO THIS POLICY</b></p>
                    <p className="parasubtext">a. Please note that We may from time to time change the terms of this Policy and every time You wish to use this website, please check the Policy to ensure You understand the terms and conditions that apply at that time.</p>
                    <p className="parasubtext">
                        b. If you do not wish to accept the revised Policy, you should not continue to use the Services. If you continue to use the Services after the date on which the changes, come into effect, Your use of the Services indicates your agreement to be bound by the new Refund Policy.
                    </p>
                    <p className="headtext"><b>11. FORCE MAJEURE</b></p>
                    <p>We shall not be considered in breach of guarantee or terms of service and shall not be liable to the Customer for any cessation, interruption, or delay in the performance of its obligations by reason beyond Our control including natural disasters, pandemics, fire, an act of God or public enemy, famine, plague, the action of the court or public authority, change in law, explosion, war, terrorism, armed conflict, labour strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen.</p>
                    <p className="headtext"><b>12. CONTACT US</b></p>
                    <p className="paratext">For any feedback, concern, or query, You may please reach out to Us on the contact details below.</p>
                    <p className="paratext">Customer Care: support@Pethood.pet</p>
                </div>
            </div>
        </>
    )
}