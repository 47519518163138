import React from "react";

import ContactUs from "./ContactUs";
import Landingpage from "./Landingpage";
import Mobilepage from "./Mobilepage";
import Dog from "./Dog";
import AboutPage from "./AboutPage";

const Home = () => {
  return (
    <div>
      <Landingpage />
      <hr className="main-line" />
      <Dog />
      <hr className="main-line" /> <Mobilepage />
      <hr className="main-line" />
      <AboutPage />
      <hr className="main-line" />
      <ContactUs />
      {/* <div className="bottom-contact ">
        <center>
          <p className="bottom-para">
            Copyright One Love 2022, All Rights Reserved <br /> Chicago, IL
          </p>
          <p
            // onClick={scrollToContact}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
            }}
          >
            <Link to="/contact"> Contact Us</Link>
          </p>
        </center>
      </div> */}
    </div>
  );
};

export default Home;
