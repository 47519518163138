// src/redux/userReducer.js
import { createReducer } from '@reduxjs/toolkit';
import { actionTypes } from './Actions';

const initialState = {
    userData: [], // Changed to an empty array as per your requirement
    token: null,
};

const Reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actionTypes.getUserData, (state, action) => {
            return {
                ...state,
                userData: action.payload ?? [], // Set userData to the action payload or an empty array
            };
        })
        .addCase(actionTypes.clearUserData, (state) => {
            return {
                ...state,
                userData: [], // Clear userData to an empty array
                token: null,
            };
        })
        .addCase(actionTypes.setToken, (state, action) => {
            return {
                ...state,
                token: action.payload, // Set the token to the action payload
            };
        })
        .addCase(actionTypes.clearToken, (state) => {
            return {
                ...state,
                token: null, // Clear the token
            };
        });
});

export default Reducer;
