import React from "react";

const FAQs = () => {
  return (
    <div>
      <center>
        <h1>FAQs</h1>
      </center>
    </div>
  );
};

export default FAQs;
