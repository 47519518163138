export const actionTypes = {
    getUserData: 'GET_USER_DATA',
    clearUserData: 'CLEAR_USER_DATA',
    setToken: 'SET_TOKEN',
    clearToken: 'CLEAR_TOKEN',
};

export const getUserData = (data) => {
    return {
        type: actionTypes.getUserData,
        payload: data,
    };
};

// Action creator for clearing user data
export const clearUserData = () => {
    return {
        type: actionTypes.clearUserData,
    };
};

// Action creator for setting the token
export const setToken = (token) => {
    return {
        type: actionTypes.setToken,
        payload: token,
    };
};

// Action creator for clearing the token
export const clearToken = () => {
    return {
        type: actionTypes.clearToken,
    };
};