import axios from 'axios';
import apiconfig from '../Config/Config';

const avdurl1 = apiconfig.develpoment.apibaseUrl;

const api = axios.create({
  baseURL: avdurl1,
});

const token = localStorage.getItem('accessToken');
      console.log('api token', token);

async function getBearerToken() {
    try {
      const token = localStorage.getItem('accessToken');
      console.log('api token', token);
      return token;
    } catch (err) {
      console.log('error', err);
    }
  };
  
  api.interceptors.request.use(
    async config => {
      try {
        const token = await getBearerToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      } catch (err) {
        console.error('Request interceptor error', err);
        return config;
      }
    },
    error => {
      console.error('Request interceptor error', error);
      return Promise.reject(error);
    },
  );

  // User Profile API

export const userProfileData = async (mobile, body) => {
    try {
      const response = await api.post(
        avdurl1 + 'registration/registration-mobile-number',
        body,
        {
          params: {
            mobile_number: mobile,
          },
        },
      );
      return response.data;
    } catch (err) {
      console.log('api Error', err);
      return null;
    }
  };

  //Delete use account
export const deleteAccount = async user_id => {
    try {
      const response = await api.delete(avdurl1 + 'registration/delete-user', {
        params: {
          user_id: user_id,
        },
      });
      return response.data?.message;
    } catch (err) {
      console.log('api Error', err);
      return err.response.data?.message;
    }
  };